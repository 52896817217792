import React from "react";
import { ButtonSecundarioStyle } from './style';



function BotaoSecundario(props) {
  return (
    <ButtonSecundarioStyle
      className={props.disabled === true ? `${props.classeComplementar} 'disabled'` : `${props.classeComplementar}`}
      onClick={props.funcao}
      disabled={props.disabled}
      aria-disabled={props.disabled === true ? "true":"false"}
    >
      {props.nome}
    </ButtonSecundarioStyle>
  )
}

export default BotaoSecundario