import React, { useState } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';
import { useStore } from 'react-redux';
import WeexMessage from '../../comps/WeexMessage';
import BotaoSecundario from '../../comps/botaoSecundario/BotaoSecundario';
import WeexModal from '../../comps/weexModais/WeexModal';
function CustomRegisterCampaignMobile(props) {
  const i18n = useStore().getState().i18n;
    
  const [unidadeMae, setUnidadeMae] = useState(null)
  const [subareas, setSubareas] = useState()

  const mudarUnidade = (event) =>{
    event.preventDefault();
    const value = event.target.value;
    setUnidadeMae(value)
    setSubareas(props.campanha.client.units.filter(unidade => unidade.unidadeMae === value))

    props.onChange(event);
  }

  const inputUnidade = () => {
    if(props.campanha.client.units && props.campanha.client.units.length > 0){
      return(
        <div className="field">
          <label htmlFor="selectUnit" className="label" ><WeexMessage code="registro.campo.area" default={i18n.message('select.unidade.title',"Selecione sua área")}/>
            <div className="control select-margin-top-control">
              <select id="selectUnit" className='select-input input is-medium' value={props.request.unit} name="unit" onChange={(e) => mudarUnidade(e)} required>
                <option value={null} >{i18n.message('select.unidade', 'Selecione uma unidade')}</option>
                {
                  props.campanha.client.units.filter(unidade => unidade.unidadeMae === null).map((opcao) => {
                    return <option value={opcao.uuid} key={opcao.uuid}>{opcao.name}</option>
                  })
                }
              </select>
            </div>
          </label>
        </div>
      )

    }  
    return null;
  }

  const inputSubUnidade = () => {
    if(unidadeMae !== null && subareas.length > 0){
      return(
        <div className="field">
          <label htmlFor="selectSubunit" className="label"><WeexMessage code="registro.campo.subarea" default="Selecione sua subárea" />
            <div className="control select-margin-top-control">
              <select id="selectSubunit" className='select-input input is-medium' value={props.request.subUnit} name="subUnit" onChange={props.onChange}>
                <option value={null} >{i18n.message('select.subárea', 'Selecione uma subárea')}</option>
                {
                  subareas.map((opcao) => {
                    return <option value={opcao.uuid} key={opcao.uuid}>{opcao.name}</option>
                  })
                }
              </select>
            </div>
          </label>
        </div>
      )

    }  
    return null;
  }
  return(
    <form onSubmit={props.registro}>
      { props.erro != null ? (
        <WeexModal 
          fecharModalCallback={props.callbackWarning}
          titulo={props.erro.titulo} 
          conteudo={props.erro.mensagem}
        />
      ) : null}
            
      {props.fields.map(function (el, index) {
        return (
                    
          props.renderizaCampo(el, index)
        );
      })}

            

      { inputUnidade()}
      {inputSubUnidade()}

      <label className="checkbox" style={{textAlign:'center', marginTop:'2.0em', marginBottom:'2.0em'}} aria-label={i18n.message("aria.label.campo.registro.legal.termos", " Aceitar termos e condições ")}>
        <input onChange={props.onChangeAccept} type="checkBox" name="aceito"/>&nbsp;&nbsp;{i18n.message("registro.legal.liaceito", "Li e aceito os")} 
        <a className="link-sublinhado" href={props.code === "rededor" ? "https://weex.digital/termos-rede-dor/":props.campanha.privacyPolicy ? props.campanha.privacyPolicy : "https://weex.digital/politica-de-privacidade-e-uso/"} target="_blank" rel="noopener noreferrer">{i18n.message("registro.legal.termos", " Termos e Condições ")}</a> 
        {i18n.message("registro.legal.daweex", "da Weex")}
                
                
      </label>
      <ReCaptcha
        sitekey={props.recaptchaToken}
        action='register'
        verifyCallback={props.verifyCallback}
      />

      <p style={{textAlign:'center', marginTop:'1.0em'}}>
        <BotaoSecundario disabled={props.accept || props.captcha ? false : true} nome={props.processando ? i18n.message("geral.aguarde", "AGUARDE...") : i18n.message("registro.criar", "CADASTRAR")} />
        <button className='button-link espacamento-inicio' type='button' onClick={props.modoLogin}>{i18n.message("registro.posseuiCadastro", "Já possuo cadastro")}</button>
      </p>
    </form>
  )

}

export default CustomRegisterCampaignMobile;