import React, { useEffect, useState } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WeexInput from '../../comps/WeexInput';
import CampanhaService from '../../services/CampanhaService';
import TemaService from '../../services/TemaService';
import CustomRegisterCampaignDesktop from './CustomRegisterCampaignDesktop';
import CustomRegisterCampaignMobile from './CustomRegisterCampaignMobile';
import { RegisterCampaignContainerStyle } from "./style";


function CustomRegisterCampaign(props){
  const [request, setRequest] = useState({firstName:null, email:null, lastName:null, password1:null, password2:null, birthday:null, unit: null, subUnit:null, age:18})
  const [accept, setAccept] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [erro, setErro] = useState(null)
  const [processando, setProcessando] = useState(false)
  const history = useHistory()
    
  const i18n = useStore().getState().i18n; 
  const dispatch = useDispatch();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
    
  const service = new CampanhaService()
  const campanha = service.campanhaCorrente();
  const {code} =campanha;
  const coletaIdade = campanha.ageType;
  const expoeDataNascimento = (coletaIdade === 'DATE')
  const expoeIdade = (coletaIdade === 'AGE')

  const minScore =  process.env.REACT_APP_RECAPTCHA_MIN_SCORE;
  const recaptchaToken =  process.env.REACT_APP_RECAPTCHA_SITE_TOKEN;

  const onChange = (event) => {
    let req = request
    req[event.target.name] = event.target.value
        
    setRequest(req)
  }

  const onChangeAccept = (event) => {
    setAccept(event.target.checked)
  }

  const registro = (event) => {
    if (! props.campanha) {
      alert("Sem campanha")
    }

    event.preventDefault()
    if (processando) {
      return
    }
        
    setProcessando(true)
    if(campanha.registrationUnitMandatory && props.campanha.client && props.campanha.client.units && props.campanha.client.units.length > 0){
      if(request.unit === null ){
        setErro({
          titulo:i18n.message("registro.erro.campo.invalido", "Campo inválido"), 
          mensagem:i18n.message("registro.erro.unidade", "Você deve escolher uma unidade")})
        setProcessando(false)
        return
      }
      if(request.subUnit === null){
        setErro({
          titulo:i18n.message("registro.erro.campo.invalido", "Campo inválido"), 
          mensagem:i18n.message("registro.erro.subunidade", "Você deve escolher uma sub-unidade")})
        setProcessando(false)
        return
      }

    }
    if (! accept) {
      setErro({
        titulo:i18n.message("registro.erro.legal.titulo", "Aceite nossas condições"), 
        mensagem:i18n.message("registro.erro.legal.mensagem", "Você não aceitou os termos e condições do Weex.")})
      setProcessando(false)
      return
    }
    if (expoeDataNascimento && possuiCampo('IDADE')) {
      if(request.birthday.length !== 10){
        setErro({
          titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
          mensagem:i18n.message("registro.erro.nascimento.mensagem", "A data de nascimento que você digitou é inválida.")
        })
        setProcessando(false)
        return
      }
      let camposData = request.birthday.split("/")
      if (props.campanha.tipoInscricao === 'MATRICULA') {
        if(new Date() < new Date((parseInt(camposData[2]) + 18), (parseInt(camposData[1]) - 1), camposData[0])){
          setErro(
            {
              titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
              mensagem:i18n.message("registro.erro.nascimento18.mensagem", "Idade menor que 18.")})
          setProcessando(false)
          return
        }
      } else {
        if(new Date() < new Date((parseInt(camposData[2]) + 13), (parseInt(camposData[1]) - 1), camposData[0])){
          setErro({
            titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
            mensagem:"Idade menor que 13."})
          setProcessando(false)
          return
        }
      }
      if(camposData[1] > 12 || camposData[1] < 1){
        setErro({
          titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
          mensagem:i18n.message("registro.erro.nascimentomes.mensagem", "Mês " + camposData[1] + " é inválido, meses válidos entre 1 e 12 inclusos esses valores.")})
        setProcessando(false)
        return
      }
      let ultimoDiaMes = new Date(camposData[2], camposData[1], 0).getDate()
      if(camposData[0] > ultimoDiaMes || camposData[0] < 1){
        setErro({
          titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
          mensagem: i18n.message("registro.erro.nascimentodia.mensagem",  "Dia " + camposData[0] + " é inválido, válidos para o mês " + camposData[1] + "/" + camposData[2] + " entre 1 e " + ultimoDiaMes + " inclusos esses valores.")})
        setProcessando(false)
        return
      }            
    }


    if (expoeIdade) {
      if (props.campanha.tipoInscricao === 'MATRICULA') {
        if(request.age < 18){
          setErro({
            titulo:i18n.message("registro.erro.idade.titulo", "Idade inválida"), 
            mensagem:i18n.message("registro.erro.idade.menor18", "Menor que 18.")})
          setProcessando(false)
          return
        }
      } else if(request.age < 13){
        setErro({
          titulo:i18n.message("registro.erro.idade.titulo", "Idade inválida"), 
          mensagem:i18n.message("registro.erro.idade.menor13", "Menor que 13.")})
        setProcessando(false)
        return
      }
    }

    if (request.password1 !== request.password2) {
      setErro({
        titulo:i18n.message("registro.erro.senhas.titulo", "Senhas não conferem"), 
        mensagem:i18n.message("registro.erro.senhas.mensagem", "As senhas que você digitou não conferem.")})
      setProcessando(false)
      return
    }

        
    const inscricao = {birthDate: request.birthday,
      enrollment:request.enrollment, 
      email:request.email, 
      firstName:request.firstName, 
      lastName:request.lastName, 
      password:request.password1, 
      campaign:props.campanha.code,
      unit: request.unit,
      age: request.age}
    service.inscrever(inscricao, (erro, sucesso) => {
      if (erro) {
        setProcessando(false)
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem:erro.response.data.message})
        } else {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem: i18n.message("geral.erro.network.mensagem", "Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde")})
        }
        return
      } 
      if (sucesso) {
        const temaService = new TemaService()
        temaService.removerTema()
        setProcessando(false)
        if (sucesso.authorization) {
          dispatch({type: "usuario", payload: true});
          props.callbackLogin(sucesso)
          service.contadorCampanha((erro, sucesso)=>{
            if(erro){
              setErro({titulo: i18n.message("atividades.erro.dias.habilitados", "Erro obter lista de dias habilitados")})
              return;
            }
            if(sucesso){
              if(sucesso !== null){
                if(sucesso.length > 0 ){
                  history.push('/atividades')
                } else {
                  history.push("/home")
                }
              }
            }
          })
        } else {
          setErro({titulo:i18n.message("registro.erro.aposInscricao.titulo", "Sobre sua inscrição"), 
            mensagem:sucesso.message})
        }
      }

    })
  }

  const callbackWarning = () => {
    setErro(null)
  }

    

  const verifyCallback = (recaptchaToken) => {
    service.validarRecaptcha(recaptchaToken, (erro, sucesso) => {
      if(sucesso){
        if(sucesso.success && parseFloat(sucesso.score) > parseFloat(minScore)){
          setCaptcha(true)
        }else{
          setCaptcha(false)
          setErro({
            titulo:i18n.message("registro.erro.recaptcha.titulo", "Recaptcha"), 
            mensagem:i18n.message("registro.erro.recaptcha.mensagem", "A verificação do recaptcha falhou")})
        }
      }else if (erro) {
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem:erro.response.data.message})
        } else {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem: i18n.message("geral.erro.network.mensagem", "Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde")})
        }
        return
      }
    })
  }
    
  useEffect(() => {
    if(new CampanhaService().campanhaCorrente().captcha){
      loadReCaptcha(recaptchaToken, () => {});
    } else {
      setCaptcha(true)
    }
  }, [recaptchaToken])

    
  if (! campanha || ! campanha.tipoInscricao) {

    new CampanhaService().clearAll()
    if (! window.location.href.endsWith("select")) {
      window.location = "/select"
    }

  }

  const fields = campanha.fields
  fields.sort(function (f1, f2) {
    if(f1.order > f2.order){
      return 1
    }
    if(f1.order < f2.order){
      return -1
    }
    return 0
  })

    

  const possuiCampo = (campo) => {
    let possui = false;
    for(let i=0; i<campanha.fields.length; i++){
      if(campanha.fields[i].field === campo){
        possui = true
      }
    }
    return possui
  }

  const renderizaCampo = (f, index) => {
    let retorno = "";
    if(f.field === 'NOME'){
      return <WeexInput key={index} id="firstName" type="text" name="firstName" placeholder={f.label} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.first.name", "Insira seu nome") } />
    }else if(f.field === 'SOBRENOME'){
      return <WeexInput key={index} id="lastName" type="text" name="lastName" placeholder={f.label} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.last.name", "Insira seu sobrenome") }/>
    }else if(f.field === 'EMAIL'){
      let required = f.required
      if(!possuiCampo('MATRICULA')){
        required = true
      }
      return <WeexInput key={index} id="email" type="email" name="email" placeholder={f.label} onChange={onChange} required={required} ariaLabel={i18n.message("aria.label.campo.email", "Insira seu email") }/>
    }else if(f.field === 'SENHA'){
      if(isDesktopMode){
        return (
          <>
            <div className="field-cadastro ultima-div">
              <WeexInput id="password" key={index} type="password" name="password1" placeholder={f.label} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.password", "Insira uma senha") }/> 
            </div>
            <div className= "field-cadastro ultima-div"   >
              <WeexInput id="password2" type="password" name="password2" placeholder={i18n.message("registro.campo.senha2", "Confirme sua senha")} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.password.confirm", "Confirme sua senha") }/>
            </div>
          </>    
        )
      }else{
        return <span key={'spn' + index}>
          <div className="field-cadastro">
            <WeexInput id="password" key={index} type="password" name="password1" placeholder={f.label} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.password", "Insira uma senha") }/> 
          </div>
          <div className="field-cadastro">
            <WeexInput id="password2" type="password" name="password2" placeholder={i18n.message("registro.campo.senha2", "Confirme sua senha")} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.password.confirm", "Confirme sua senha") }/>
          </div>
        </span>       
      }               
    }else if(f.field === 'IDADE'){
      if(expoeDataNascimento){
        return <WeexInput id="birthday" key={index} type="text" name="birthday" placeholder={f.label} onChange={onChange} required={f.required} mascara="99/99/9999" legenda="" ariaLabel={i18n.message("aria.label.campo.bithday", "Insira sua data de nascimento") }/>
      }else if(expoeIdade){
        return <WeexInput id="listaNumero" key={index} type="listaNumero" name="age" placeholder={f.label} min={14} max={100} onChange={onChange} required={f.required} ariaLabel={i18n.message("aria.label.campo.idade", "Insira sua idade") }/>
      }
    }else if(f.field === 'MATRICULA'){
      let required = true
      return <WeexInput id="enrollment" key={index} type="text" name="enrollment" placeholder={f.label} onChange={onChange} required={required} ariaLabel={i18n.message("aria.label.campo.enrollment", "Insira sua matrícula") }/>
    }          
    return retorno
  }

  return (
    <RegisterCampaignContainerStyle>
            
      {
        isDesktopMode?
          <CustomRegisterCampaignDesktop
            request={request}
            onChange={onChange}
            registro={registro}
            erro={erro}
            callbackWarning={callbackWarning}
            expoeDataNascimento={expoeDataNascimento}
            expoeIdade={expoeIdade}
            onChangeAccept={onChangeAccept}
            verifyCallback={verifyCallback}
            accept={accept}
            captcha={captcha}
            processando={processando}
            modoLogin={props.modoLogin}
            campanha={campanha}
            code={code}
            fields={fields}
            renderizaCampo={renderizaCampo}
            recaptchaToken={recaptchaToken}
          >

          </CustomRegisterCampaignDesktop>
          :
          <CustomRegisterCampaignMobile
            request={request}
            onChange={onChange}
            registro={registro}
            erro={erro}
            callbackWarning={callbackWarning}
            expoeDataNascimento={expoeDataNascimento}
            expoeIdade={expoeIdade}
            onChangeAccept={onChangeAccept}
            verifyCallback={verifyCallback}
            accept={accept}
            captcha={captcha}
            processando={processando}
            modoLogin={props.modoLogin}
            campanha={campanha}
            code={code}
            fields={fields}
            renderizaCampo={renderizaCampo}
            recaptchaToken={recaptchaToken}
          >
          </CustomRegisterCampaignMobile>
      }

    </RegisterCampaignContainerStyle>
  )
}

export default CustomRegisterCampaign;
