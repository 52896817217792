import axios from 'axios';

export default class UsuarioService {



  /**
     * Retorna o usuario atualmente autenticado na aplicação
     */
  usuarioCorrente() {
    let conteudo = localStorage.getItem('usuario')
    if (conteudo) {
      return JSON.parse(conteudo)
    } else {
      return null;
    }
  }

  /**
     * Retorna se o usario é um administrador
     */
  isAdmin() {
    let corrente = this.usuarioCorrente()
    if (corrente && corrente.roles && corrente.roles.length > 0) {
      return corrente.roles.includes("ROLE_ADMIN")
    } else {
      return false
    }
  }

  /**
     * Retorna se o usario é um administrador
     */
  isGestor() {
    let corrente = this.usuarioCorrente()
    if (corrente && corrente.roles && corrente.roles.length > 0) {
      return corrente.roles.includes("ROLE_GESTOR_CAMPANHA")
    } else {
      return false
    }
  }

  setUsuarioCorrente(usuario){
    localStorage.setItem('usuario', JSON.stringify(usuario))
  }

  removeUsuarioCorrente() {
    localStorage.removeItem('usuario')
  }

  token() {
    let corrente = this.usuarioCorrente()
    if (corrente) {
      return corrente.token
    } else {
      return null
    }
  }

  /**
     * Realiza o logout do usuário na aplicação
     * 
     * O callback é executado após o logout ser bem sucedido
     * @param {*} callback 
     */
  logout(callback) {
    localStorage.removeItem('usuario')
    localStorage.removeItem('agenda')
    localStorage.removeItem('validade-cache-agenda')
    localStorage.removeItem("check-start-campanha");
    localStorage.removeItem("time-to-next-request");
    localStorage.removeItem("date-request-time");
    localStorage.removeItem("validade-cache-aviso");
    localStorage.removeItem("usuario");
    localStorage.removeItem("aviso");
    callback()
  }

  /**
     * Realiza a autenticação no sistema.
     * 
     * Credenciais consiste em um objeto com três campos: username, password, campaign - o terceiro é o código da campanha
     * @param {*} credenciais 
     * @param {*} callback 
     */
  auth(credenciais, callback) {
    axios.post(this.host() + '/api/v1/auth', credenciais)
      .then((response) => {
        localStorage.setItem('usuario', JSON.stringify(response.data))
        callback(null, response.data)
      })
      .catch((error) => {
        localStorage.removeItem('usuario')
        callback(error, null)
      })
  }

  host() {
    return process.env.REACT_APP_HOST_API
  }

}