import Vimeo from '@u-wave/react-vimeo';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BotaoAsALink from '../../comps/botaoAsALink/BotaoAsALink';
import BotaoAsALinkIconeEsquerda from '../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft';
import DinamicasHeader from '../../comps/dinamicasHeader/DinamicasHeader';
import AgendaService from '../../services/AgendaService';
import ExtraService from '../../services/ExtraService';
import { ExtraStyle } from './style';

class Extra extends Component {
    altura = this.props.isDesktopMode ? window.screen.height - window.screen.height * 0.38 : window.screen.height - window.screen.height * 0.1;
    largura =  this.props.isDesktopMode ? window.screen.width - window.screen.width * 0.06 : window.screen.width - window.screen.width * 0.0;

    alturaVideo = this.props.isDesktopMode ? window.screen.height - window.screen.height * 0.38 : ((window.screen.width * 0.8) / 16) * 9;
    larguraVideo =  this.props.isDesktopMode ? window.screen.width - window.screen.width * 0.06 : window.screen.width * 0.8  ;
    css = {
      width: "100%"
    };
    iframe = null
    state = {
      codigo:null,
      codigoAtividade:null,
      action:null,
      extra:null, 
      processando:false,
      redirecionando: false,
      tipoApresentacao: null,
      sizeScreen : window.innerWidth,
      isVideo: false,
      erro: {
        isWrong: false,
        titulo: null,
        mensagem: null
      }
    }

    componentDidMount() {
      if (this.state.processando) {
        return
      }
      this.setState({processando:true})
      const codigo = this.props.match.params.codigo
      const codigoAtividade = this.props.match.params.codigoAtividade
      const service = new ExtraService()
      const agendaService = new AgendaService()
      service.iniciarConteudoExtra(codigo, codigoAtividade, (erro, action) => {
        this.setState({processando:false})
        if(action){
          agendaService.atualizarAgenda(codigo, codigoAtividade, "EXTRA", null, null, (error, sucesso) => {
            if(error){
              alert( this.props.i18n.message("dinamica.erro.jaexecutada.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte") )
            }
          })
        }
        if (erro) {
          alert(JSON.stringify(erro))
          return
        }

        this.setState({codigo:codigo, 
          codigoAtividade:codigoAtividade,
          action:action,
          tipoApresentacao: action.extra.typeApresentation, 
          extra:action.extra})

        this.executarTipoApresentacao(this.state.extra)
        this.setState({redirecionando: false})
      })
    }

    createYouTubeEmbedLink = (link) => {
      const videoId = link.split("https://www.youtube.com/watch?v=")[1];
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;

      const queryParamsIndex = videoId.indexOf("&");
      if (queryParamsIndex !== -1) {
        // A URL já possui parâmetros adicionais.
        const queryParams = videoId.slice(queryParamsIndex + 1);
        return `${embedUrl}?${queryParams}&autoplay=1`;
      } else {
        // A URL não possui parâmetros adicionais.
        return `${embedUrl}?autoplay=1`;
      }
    };

    createGoogleDocEmbedLink = (link) => {
      return link.replace("view?usp=sharing", "preview");
    }

    proporcao = ()=> { return this.state.sizeScreen > 700 ? 0.5 : 0.8}

    createVimeoIframe = (link) => {

      return (
        <div className='columns iframe-extra'>
          <div className='column is-full text-align' >
            <Vimeo
              video={link}
              width={this.larguraVideo}
              height={this.alturaVideo}
              autoplay
            > </Vimeo>
          </div>

        </div>
       
      )
    }

    verificarseURLContemYoutube = (url) => {
      return url.includes("youtube.com");
    }
    verificarSeUrlGoogleDrive = (url) => {
      return url.includes("drive.google.com");
    }

    verificarSeUrlIsIframe = (url) => {
      return url.includes("<iframe");
    }

    verificarSeUrlVimeo = (url) => {
      return url.includes("vimeo.com");
    }

    gerarHtmliframe(url){
      return(
        <div className='columns iframe-extra'
          dangerouslySetInnerHTML={{__html: url}} >
                
        </div>
      )
    }

    gerarHtmlLink (url){
      return (
        <div className='columns iframe-extra'>
          <div className='is-full'>
            <iframe 
              src={url}
              allowFullScreen
              title='Atividade extra'
              allow="autoplay; picture-in-picture"
              height={this.state.isVideo ? this.alturaVideo:this.altura} 
              width={this.state.isVideo ? this.larguraVideo:this.largura} />
          </div>
        </div>
      )
    }

    getExtra = (isIframe, url) => {
      if(this.verificarSeUrlVimeo(url)){
        return this.createVimeoIframe(url)
      }else if (isIframe){
        return this.gerarHtmliframe(url)
      }else{
        return this.gerarHtmlLink(url)
      }

    }

    executarTipoApresentacao = (extra) => {
      const { history } = this.props;
      if(extra.typeApresentation === 'REDIRECT' || this.props.isDesktopMode === false){
        window.location = extra.url;
            
      }else{
        this.setState({tipoApresentacao: extra.typeApresentation})
        let url = extra.url;
        let isIframe = this.verificarSeUrlIsIframe(url);
        if(this.verificarseURLContemYoutube(url)){
          this.setState({isVideo: true})
          url = this.createYouTubeEmbedLink(url)
        }
        if(this.verificarSeUrlGoogleDrive(url)){
          url = this.createGoogleDocEmbedLink(url)
        }
        if(this.verificarSeUrlVimeo(url)){
          this.setState({isVideo: true})
          isIframe = true;                
        }
            
        this.iframe = (
          <div className='columns'>
            <div className='is-full'>
              {this.props.isDesktopMode ?
                <div className='desktop-voltar'>
                  <BotaoAsALinkIconeEsquerda
                    icon="fas fa-arrow-left "
                    classeComplementar="header-como-funciona-desktop-botao"
                    nome={this.props.i18n.message("geral.voltar", "Voltar")}
                    ariaLabelMsg={this.props.i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                    funcao={() => {
                      history.push("/home")
                    }}
                  />
                </div> : ""
              }
              <div className='extra-iframe'>
                {this.getExtra(isIframe, url)}
              </div>
                        
            </div>

          </div>
        ) 
            
      }
    }

    voltar = () => {
      window.location = "/"
    }


    render() {
      let i18n = this.props.i18n

      const extra = this.state.extra
      if (! extra) {
        setTimeout(()=>{ 
          return <p>{ i18n.message("dinamica.extra.semConteudo", "Sem conteúdo...") }</p>
        }, 2000);
      }
      return (
        <ExtraStyle tamanho={this.largura}>
          <div>
            <div className="card weex-card-interno">
              <div>
                <div className="card-content">

                              
                  {this.state.tipoApresentacao === null ?
                    (
                      <>
                        <div style={{width:'100%', textAlign:'center'}}>
                          <p>{ i18n.message("geral.redirecionando", "Aguarde...") }</p>
                        </div>
                        <BotaoAsALink
                          nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                          ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                          funcao={this.voltar}
                        />
                      </>
                    ):""
                  }  
                  { this.state.tipoApresentacao === 'REDIRECT' || this.props.isDesktopMode === false ? 
                    (
                      <>
                        <DinamicasHeader
                          dinamica={extra}
                          theme={extra?.theme}
                          dinamicaNome={i18n.message("dinamica.extra.nome", "Extra")}
                          dinamicaAlt={i18n.message("alt.dinamica.extra", "Ícone de uma mão clicando em um ponto especifíco da página.")}
                          dinamicaImg={this.props.location.state.icon || "/new_board.svg" }
                        />
                        <div style={{width:'100%', textAlign:'center'}}>
                          <p>{ i18n.message("geral.redirecionando", "Aguarde...") }</p>
                        </div>
                        <BotaoAsALink
                          nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                          ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                          funcao={this.voltar}
                        />
                      </>
                    ): this.iframe
                                
                  }

                </div>
              </div>
            </div>
          </div>
        </ExtraStyle>
      )
    }
}

const mapStateToProps = function(state) {
  return {
    i18n:state.i18n,
    isDesktopMode: state.isDesktopMode

  }
}

export default connect(mapStateToProps)(Extra)