import styled from "styled-components";
export const ExtraStyle = styled.div`
    .iframe-extra{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .desktop-voltar{
        display: none;
    }
    .card-content{
        padding-left: 0px;
        padding-right: 0px;
    }
    .columns{
        margin: 0px;
    }
    .weex-container{
        padding-left: 0px ;
        padding-right: 0px;
    }

    .text-align{
        text-align: center;
    }

    extra-iframe {
        margin-top: 2em;
    }
    @media (min-width: 768px) {
        .desktop-voltar{
            display: block;
            margin: 1em;
            min-width:${props => props.tamanho || null}px; 
        }
        .card-content{
            padding: 0;
        }
    }
`