import React, { useEffect, useState } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CampanhaService from '../../services/CampanhaService';
import TemaService from '../../services/TemaService';
import RegisterCampaignDesktop from './RegisterCampaignDesktop';
import RegisterCampaignMobile from './RegisterCampaignMobile';
import { RegisterCampaignContainerStyle } from "./style";


function RegisterCampaign(props) {
  const [request, setRequest] = useState({firstName:null, email:null, lastName:null, password1:null, password2:null, birthday:null, unit: null, age:18, subUnit:null})
  const [accept, setAccept] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [erro, setErro] = useState(null)
  const [processando, setProcessando] = useState(false)
  const history = useHistory()
  const service = new CampanhaService()
  const campanhaCorrente = service.campanhaCorrente();
  const isDesktopMode = useSelector((state) => state.isDesktopMode); 

  const i18n = useStore().getState().i18n; 
  const dispatch = useDispatch();
  const recaptchaToken =  process.env.REACT_APP_RECAPTCHA_SITE_TOKEN;
  const minScore =  process.env.REACT_APP_RECAPTCHA_MIN_SCORE;
    
    
  const {code} =campanhaCorrente;
  const tipoInscricao = campanhaCorrente.tipoInscricao;
  const nomeMatricula = campanhaCorrente.nomeMatricula;
  const coletaIdade = campanhaCorrente.ageType;

  const expoeCampoEmail = (tipoInscricao !== 'MATRICULA')
  const expoeCampoMatricula = (tipoInscricao !== 'EMAIL')
  const expoeDataNascimento = (coletaIdade === 'DATE')
  const expoeIdade = (coletaIdade === 'AGE')

  const callbackWarning = () => {
    setErro(null)
  }    

  const onChange = (event) => {
    let req = request
    req[event.target.name] = event.target.value
        
    if(event.target.name === 'unit'){
      req['subUnit'] = null
    }

    setRequest(req)
  }

  const onChangeAccept = (event) => {
    setAccept(event.target.checked)
  }

  const registro = (event) => {

    if (! props.campanha) {
      alert("Sem campanha")
    }

    event.preventDefault()
    if (processando) {
      return
    }
    setProcessando(true)
       
    if(props.campanha.registrationUnitMandatory && props.campanha.client && props.campanha.client.units && props.campanha.client.units.length > 0){
      if(request.unit === null){
        setErro({
          titulo:i18n.message("registro.erro.campo.invalido", "Campo inválido"), 
          mensagem:i18n.message("registro.erro.unidade", "Você deve escolher uma unidade")})
        setProcessando(false)
        return
      }
      if(request.subUnit === null){
        setErro({
          titulo:i18n.message("registro.erro.campo.invalido", "Campo inválido"), 
          mensagem:i18n.message("registro.erro.subunidade", "Você deve escolher uma sub-unidade")})
        setProcessando(false)
        return
      }

    }

    if (! accept) {
      setErro({
        titulo:i18n.message("registro.erro.legal.titulo", "Aceite nossas condições"), 
        mensagem:i18n.message("registro.erro.legal.mensagem", "Você não aceitou os termos e condições do Weex.")})
      setProcessando(false)
      return
    }
    if (expoeDataNascimento) {
      if(request.birthday.length !== 10){
        setErro({
          titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
          mensagem:i18n.message("registro.erro.nascimento.mensagem", "A data de nascimento que você digitou é inválida.")
        })
        setProcessando(false)
        return
      }
      let camposData = request.birthday.split("/")
      if (props.campanha.tipoInscricao === 'MATRICULA') {
        if(new Date() < new Date((parseInt(camposData[2]) + 18), (parseInt(camposData[1]) - 1), camposData[0])){
          setErro(
            {
              titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
              mensagem:i18n.message("registro.erro.nascimento18.mensagem", "Idade menor que 18.")})
          setProcessando(false)
          return
        }
      } else {
        if(new Date() < new Date((parseInt(camposData[2]) + 13), (parseInt(camposData[1]) - 1), camposData[0])){
          setErro({
            titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
            mensagem:"Idade menor que 13."})
          setProcessando(false)
          return
        }
      }
      if(camposData[1] > 12 || camposData[1] < 1){
        setErro({
          titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
          mensagem:i18n.message("registro.erro.nascimentomes.mensagem", "Mês " + camposData[1] + " é inválido, meses válidos entre 1 e 12 inclusos esses valores.")})
        setProcessando(false)
        return
      }
      let ultimoDiaMes = new Date(camposData[2], camposData[1], 0).getDate()
      if(camposData[0] > ultimoDiaMes || camposData[0] < 1){
        setErro({
          titulo:i18n.message("registro.erro.nascimento.titulo", "Data de nascimento inválida"), 
          mensagem: i18n.message("registro.erro.nascimentodia.mensagem",  "Dia " + camposData[0] + " é inválido, válidos para o mês " + camposData[1] + "/" + camposData[2] + " entre 1 e " + ultimoDiaMes + " inclusos esses valores.")})
        setProcessando(false)
        return
      }            
    }

    if (expoeIdade) {
      if (props.campanha.tipoInscricao === 'MATRICULA') {
        if(request.age < 18){
          setErro({
            titulo:i18n.message("registro.erro.idade.titulo", "Idade inválida"), 
            mensagem:i18n.message("registro.erro.idade.menor18", "Menor que 18.")})
          setProcessando(false)
          return
        }
      } else if(request.age < 13){
        setErro({
          titulo:i18n.message("registro.erro.idade.titulo", "Idade inválida"), 
          mensagem:i18n.message("registro.erro.idade.menor13", "Menor que 13.")})
        setProcessando(false)
        return
      }
    }

    if (request.password1 !== request.password2) {
      setErro({
        titulo:i18n.message("registro.erro.senhas.titulo", "Senhas não conferem"), 
        mensagem:i18n.message("registro.erro.senhas.mensagem", "As senhas que você digitou não conferem.")})
      setProcessando(false)
      return
    }

        
    const inscricao = {birthDate: request.birthday,
      enrollment:request.enrollment, 
      email:request.email, 
      firstName:request.firstName, 
      lastName:request.lastName, 
      password:request.password1, 
      campaign:props.campanha.code,
      unit: request.unit,
      subUnit: request.subUnit,
      age: request.age}
    service.inscrever(inscricao, (erro, sucesso) => {
      if (erro) {
        setProcessando(false)
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem:erro.response.data.message})
        } else {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem: i18n.message("geral.erro.network.mensagem", "Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde")})
        }
        return
      } 
      if (sucesso) {
        const temaService = new TemaService()
        temaService.removerTema()
        setProcessando(false)
        if (sucesso.authorization) {
          dispatch({type: "usuario", payload: true});
          props.callbackLogin(sucesso)
          service.contadorCampanha((erro, sucesso)=>{
            if(erro){
              setErro({titulo: i18n.message("atividades.erro.dias.habilitados", "Erro obter lista de dias habilitados")})
              return;
            }
            if(sucesso){
              if(sucesso !== null){
                if(sucesso.length > 0 ){
                  history.push('/atividades')
                } else {
                  history.push("/home")
                }
              }
            }
          })
        } else {
          setErro({titulo:i18n.message("registro.erro.aposInscricao.titulo", "Sobre sua inscrição"), 
            mensagem:sucesso.message})
        }
      }

    })
  }
    
    

  const verifyCallback = (recaptchaToken) => {
    const service = new CampanhaService()
    service.validarRecaptcha(recaptchaToken, (erro, sucesso) => {
      if(sucesso){
        if(sucesso.success && parseFloat(sucesso.score) > parseFloat(minScore)){
          setCaptcha(true)
        }else{
          setCaptcha(false)
          setErro({
            titulo:i18n.message("registro.erro.recaptcha.titulo", "Recaptcha"), 
            mensagem:i18n.message("registro.erro.recaptcha.mensagem", "A verificação do recaptcha falhou")})
        }
      }else if (erro) {
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem:erro.response.data.message})
        } else {
          setErro({
            titulo:i18n.message("registro.erro.inscricao.titulo", "Erro na inscrição"), 
            mensagem: i18n.message("geral.erro.network.mensagem", "Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde")})
        }
        return
      }
    })
  }
    
  useEffect(() => {
    if(new CampanhaService().campanhaCorrente().captcha){
      loadReCaptcha(recaptchaToken, () => {});
    } else {
      setCaptcha(true)
    }
  }, [recaptchaToken])

    
  if (! campanhaCorrente || ! campanhaCorrente.tipoInscricao) {

    new CampanhaService().clearAll()
    if (! window.location.href.endsWith("select")) {
      window.location = "/select"
    }

  }
    
  return (
    <RegisterCampaignContainerStyle>
      {
        isDesktopMode ? (
          <RegisterCampaignDesktop
            request={request}
            onChange={onChange}
            registro={registro}
            erro={erro}
            callbackWarning={callbackWarning}
            expoeDataNascimento={expoeDataNascimento}
            expoeIdade={expoeIdade}
            expoeCampoMatricula={expoeCampoMatricula}
            expoeCampoEmail={expoeCampoEmail}
            onChangeAccept={onChangeAccept}
            verifyCallback={verifyCallback}
            accept={accept}
            captcha={captcha}
            processando={processando}
            modoLogin={props.modoLogin}
            campanha={campanhaCorrente}
            nomeMatricula={nomeMatricula}
            code={code}
            recaptchaToken={recaptchaToken}
          >
          </RegisterCampaignDesktop>
        ) :(
          <RegisterCampaignMobile
            request={request}
            onChange={onChange}
            registro={registro}
            erro={erro}
            callbackWarning={callbackWarning}
            expoeDataNascimento={expoeDataNascimento}
            expoeIdade={expoeIdade}
            expoeCampoMatricula={expoeCampoMatricula}
            expoeCampoEmail={expoeCampoEmail}
            onChangeAccept={onChangeAccept}
            verifyCallback={verifyCallback}
            accept={accept}
            captcha={captcha}
            processando={processando}
            modoLogin={props.modoLogin}
            campanha={campanhaCorrente}
            nomeMatricula={nomeMatricula}
            code={code}
            recaptchaToken={recaptchaToken}
          >
          </RegisterCampaignMobile>
        )
      }

    </RegisterCampaignContainerStyle>
  )
}

export default RegisterCampaign;
