import React, { Component } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BotaoAsALinkIconeEsquerda from '../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft';
import BotaoPrimario from "../../comps/botaoPrimario/BotaoPrimario";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import { DinamicasHeaderStyle } from "../../comps/dinamicasHeader/style";
import Publicacao from '../../comps/publicacao/PublicacaoMural';
import Spinner from '../../comps/publicacao/Spinner';
import EscreverPublicacao from '../../comps/weexModais/EscreverPublicacao.js';
import ModalComentarios from '../../comps/weexModais/ModalComentarios.js';
import AgendaService from '../../services/AgendaService';
import AtividadesService from '../../services/AtividadesService';
import GameService from '../../services/GameService';
import MuralService from '../../services/MuralService';
import { MuralStyled } from './MuralStyled';

class Mural extends Component {

  /* TODO: 
        Atualizar esse componente para a nova sintaxe React, para
        que algumas funcionalidades possam ser acessadas, como
        o uso de algums hooks que podem ser exclusivos de componentes
        criadas por função. O useStore() é um exemplo que não pode ser
        usado em componentes de classes, obrigando o i18n a ser passado
        como prop
    */

    atividadesService = new AtividadesService()
    i18n = this.props.i18n;
    mensagemExcluir = this.i18n.message("dinamica.mural.publicacao.excluir", "Tem certeza que deseja excluir essa publicação?")
    dropdow = this.i18n.message("dinamica.mural.publicacao.dropdown", "Excluir publicação")
    mensagemErroImagem = this.i18n.message("dinamica.mural.publicacao.imagem.erro", "Não foi possível processar a imagem enviada. Em caso de dúvida entre em contato com o suporte.")
    mensagemProcessando = this.i18n.message("dinamica.mural.publicacao.imagem.processando", "Estamos processando sua imagem, aguarde alguns instantes e atualize esta página.")
    ariaTextAreaPublicacao = this.i18n.message("aria.label.escrever.publicacao", "Escreva sua publicação")
    ariaButtonComentario = this.i18n.message("aria.label.mural.button.comentario", "Ao clicar abre o modal de comentários")
    ariaLabelButtonCurtida = this.i18n.message("aria.label.mural.button.curtida", "clique aqui para curtir ou descurtir a publicação")
    imagemPublicacaoMural = this.i18n.message("alt.mural.imagem.publicada", "Imagem da publicação")
    
    constructor(props) {
      super(props);
      this.state = {
        publicacao: [],
        comentario: [],
        spinner: false,
        paginaAtual: 0,
        name: "",
        modalEscrita: false,
        codigoAgenda: this.props.match.params.codigo,
        codigoAtividade: this.props.match.params.codigoAtividade,
        modalComentarios: false,
        uuidPublicacao: "",
        temMaisPublicacao: false,
        timeout: false,
        numeroPublicacao: 0,
        lupa: true,
        spinnerMural: true,
        atividade:{},
        mural: {},
        dataLength: 0,
      };

    }



    componentDidMount() {

      const codigo = this.props.match.params.codigo
      const codigoAtividade = this.props.match.params.codigoAtividade
      const service = new GameService()
      const agendaService = new AgendaService()
      service.iniciarGame(codigo, codigoAtividade, (erro, action) => {
        if (action) {
          agendaService.atualizarAgenda(codigo, codigoAtividade, "MURAL", null, null, (errorAtualizarAgenda, _sucesso) => {
            if (errorAtualizarAgenda) {
              alert(this.props.i18n.message("dinamica.erro.jaexecutada.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"))
            }
          })
        }
        if (erro) {
          alert("Não foi possível fechar a ação do mural. Entre em contato com o suporte !")
        }
      })

      this.buscar();

    }

    buscar = () => {
      const codigo = this.props.match.params.codigo
      const codigoAtividade = this.props.match.params.codigoAtividade

      const service = new MuralService();
      let resquest = {}
      service.buscarPublicacoes(codigo, codigoAtividade, resquest, (_erro, publicacao) => {
        if (publicacao) {
          this.setState({ publicacao: publicacao })
          this.setState({ numeroPublicacao: publicacao.length })
          this.setState({ spinnerMural: false});
          this.setState({ temMaisPublicacao: true,dataLength:publicacao.length});
          window.scroll(0, 0);
          window.addEventListener('scroll', this.handleScroll);
        }
      })
    }

    handleScroll = () => {
      if (this.state.temMaisPublicacao) {
        this.setState({ temMaisPublicacao: false });
        this.setState({ spinner: true });
        const codigo = this.props.match.params.codigo
        const codigoAtividade = this.props.match.params.codigoAtividade
        const service = new MuralService();
        let resquest = {
          name: this.state.name,
          page: this.state.paginaAtual + 1,
        }
        service.buscarPublicacoes(codigo, codigoAtividade, resquest, (_erro, publicacao) => {
          if (publicacao) {
            this.setState((state) => {
              return { publicacao: [...state.publicacao, ...publicacao] };
            });
            this.setState({ spinner: false });
            this.setState({ spinnerMural: false});
            if (publicacao.length > 0) {
              this.setState((state) => {
                return { paginaAtual: state.paginaAtual + 1 }
              });
              this.setState({ temMaisPublicacao: true,dataLength:publicacao.length});

            } else {
              this.setState({ temMaisPublicacao: false });
            }
          } else {
            this.setState({ spinner: false });
          }
        })
      }
    }

    abrirModalEscrita = () => {
      this.setState({ modalEscrita: true })
    }

    fecharModalEscrita = () => {
      this.setState({ modalEscrita: false })
    }

    abrirModalComentarios = () => {
      this.setState({ modalComentarios: true })
    }

    fecharModalComentarios = () => {
      this.buscar()
      this.setState({ modalComentarios: false })
    }

    comentarios = (uuid, request) => {
      const service = new MuralService();

      service.buscarComentarios(uuid, request, (erro, sucess) => {
        if (sucess) {
          this.setState({ comentario: sucess })
          this.setState({ uuidPublicacao: uuid })
          this.abrirModalComentarios()
        }
        if (erro) {
          alert(JSON.stringify(erro));
        }
      })
    }

    curtir = (like, uuid) => {
      const service = new MuralService();
      let tipo
      if (like) {
        tipo = "ADD"
      } else {
        tipo = "REMOVE"
      }
      let request = {
        tipo: tipo
      }

      service.curtirOuDescurtirPublicacao(uuid, request, (erro, _sucess) => {
        if (erro) {
          alert("Não foi possível curtir a publicação! Tente novamente mais tarde");
        }
      })
    }

    coluna1 = (publicacoes) => {

      return (
        publicacoes.map((pub,index) =>{
          if (index % 2 === 0){
            return (
              pub
            )
          }
          return null
        })
      )
    }

    coluna2 = (publicacoes) => {

      return(
        publicacoes.map((pub,index) =>{
          if (index % 2 === 1){
            return (
              pub
            )
          }
          return null
        })
      )
    }


    gerarColunas = (publicacoes) => {
      return (
        <>
          <div className="coluna-1-publicacao">
            {this.coluna1(publicacoes)}
          </div>
          <div className="coluna-2-publicacao">
            {this.coluna2(publicacoes)}
          </div>
        </>

      )
    }


    gerarPublicacaoDesktop = () => {
      let publicacoes = []
      this.state.publicacao.forEach((publicacao) => {
        let publicacaoComponent = {
          ...publicacao,
          name: publicacao.autor,
          data: publicacao.data,
          imagem: publicacao.urlImagemCode,
          conteudo: publicacao.postagem,
          isPublicacao: true,
          tempo: publicacao.tempoPublicacao,
          uuid: publicacao.uuid,
          qtdComentarios: publicacao.comentarios,
          curtiu: publicacao.curtida,
          doUsuario: publicacao.publicacaoDoUsuario,
          erroImagem: publicacao.erroImagem,
          processando: publicacao.processando,
          qtLikes: publicacao.qtLikes,
          fix: publicacao.fix,
          urlVideo: publicacao.urlVideo
        }
        publicacoes.push (
          (
            <div className="publicacoes-conteudo" key={publicacaoComponent.uuid} >
              <Publicacao
                key={publicacaoComponent.uuid}
                teste={publicacao}
                elemento={publicacaoComponent}
                comentarios={this.comentarios}
                curtir={this.curtir}
                deletar={this.deletarPublicacao}
                msgExcluir={this.mensagemExcluir}
                dropdown={this.dropdow}
                msgErroImagem={this.mensagemErroImagem}
                msgProcessando={this.mensagemProcessando}
                i18n={this.i18n}
                ariaLabelButtonComentario={this.ariaButtonComentario}
                ariaLabelButtonCurtida={this.ariaLabelButtonCurtida}
                imagemPublicacaoMural={this.imagemPublicacaoMural}
                isPublicacao={publicacaoComponent.isPublicacao} />
            </div>
          )
        )
      })
      return  this.gerarColunas(publicacoes)
    }

    gerarPublicacaoMobile = () =>{
      return (
        this.state.publicacao.map((publicacao) => {
          let publicacaoComponent = {
            ...publicacao,
            name: publicacao.autor,
            data: publicacao.data,
            imagem: publicacao.urlImagemCode,
            conteudo: publicacao.postagem,
            isPublicacao: true,
            tempo: publicacao.tempoPublicacao,
            uuid: publicacao.uuid,
            qtdComentarios: publicacao.comentarios,
            curtiu: publicacao.curtida,
            doUsuario: publicacao.publicacaoDoUsuario,
            erroImagem: publicacao.erroImagem,
            processando: publicacao.processando,
            qtLikes: publicacao.qtLikes,
            urlVideo: publicacao.urlVideo
          }
          return (
            <div className="publicacoes-conteudo">
              <Publicacao
                key={publicacaoComponent.uuid}
                teste={publicacao}
                elemento={publicacaoComponent}
                comentarios={this.comentarios}
                curtir={this.curtir}
                deletar={this.deletarPublicacao}
                msgExcluir={this.mensagemExcluir}
                dropdown={this.dropdow}
                msgErroImagem={this.mensagemErroImagem}
                msgProcessando={this.mensagemProcessando}
                i18n={this.i18n}
                ariaLabelButtonComentario={this.ariaButtonComentario}
                ariaLabelButtonCurtida={this.ariaLabelButtonCurtida}
                imagemPublicacaoMural={this.imagemPublicacaoMural}
                isPublicacao={publicacaoComponent.isPublicacao} />
            </div>
          )
        }))
    }

    publicacoesMobileOrDesktop = () => {
      if (this.props.isDesktopMode){
        return(
          <div className="publicacao-teste">
            {this.gerarPublicacaoDesktop()}
          </div>
        )


      }else{
        return this.gerarPublicacaoMobile()
      }
    }

    deletarPublicacao = (uuid) => {
      let publicacoes = this.state.publicacao.filter((publicacao) =>
        publicacao.uuid !== uuid
      );
      this.setState({ publicacao: publicacoes })
      this.setState({ numeroPublicacao: publicacoes.length })
    }

    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        this.alterarNomeBusca(event)
      }
    }
    alterarNomeBusca = (e) => {
      this.setState({ name: e.target.value })
      if (this.state.timeout) {
        clearTimeout(this.state.timeout);
        this.setState({ timeout: false })
      }

      let timeout = setTimeout(() => {
        const service = new MuralService();
        this.setState({ paginaAtual: 0 })
        let request = {
          name: this.state.name,
          page: this.state.paginaAtual,
        }
        service.buscarPublicacoes(this.state.codigoAgenda, this.state.codigoAtividade, request, (erro, publicacao) => {
          if (publicacao) {
            this.setState({ publicacao: publicacao })
            this.setState({ spinner: false });
            if (publicacao.length > 0) {
              this.setState((state) => {
                return { paginaAtual: state.paginaAtual + 1 }
              });
            } else {
              this.setState({ temMaisPublicacao: false });
            }
          } else {
            this.setState({ spinner: false });
            alert(JSON.stringify(erro));
          }
        })

      }, 1000)
      this.setState({ timeout: timeout })
    }



    createPublicacao = (request) => {
      const service = new MuralService();

      service.criarPublicacao(this.state.codigoAgenda, this.state.codigoAtividade, request, (erro, sucess) => {
        if (sucess) {
          let publicacoes = this.state.publicacao;
          publicacoes.unshift(sucess)
          this.setState({ publicacao: publicacoes })
          this.setState({ numeroPublicacao: publicacoes.length })
          this.fecharModalEscrita()
          window.scrollTo()
        }
        if (erro) {
          alert(JSON.stringify(erro));
        }
      })
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }
    render() {
      const { history, location } = this.props;
      let i18n = this.props.i18n;
      return (

        <MuralStyled>
          <div className="full-width-desktop">
            <div className = "dinamica-content-desktop">
              <div className="div-close">
                <Link
                  to="/"
                  className="close-btn img-close"
                  aria-label={i18n.message("aria.label.fechar.modal", "Fecha a janela e volta para a página atividades.")}
                >
                  <img
                    src="/close-btn.png"
                    alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
                  />
                </Link>
              </div>
              <div className='botao-voltar-desktop'>
                <div className="botao-voltar">
                  <BotaoAsALinkIconeEsquerda
                    icon="fas fa-arrow-left "
                    classeComplementar="header-como-funciona-desktop-botao"
                    nome={i18n.message("geral.voltar", "Voltar")}
                    ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                    funcao={() => {
                      history.push("/home")
                    }}
                  />
                </div>
              </div>
              {this.state.modalEscrita && (
                <EscreverPublicacao 
                  ariaTextArea={this.ariaTextAreaPublicacao}  
                  fecharModalCallback={this.fecharModalEscrita} 
                  create={this.createPublicacao}  
                  isPublicacao={true}
                />
              )}

              {this.state.modalComentarios ?
                (<ModalComentarios
                  fecharModalCallback={this.fecharModalComentarios} 
                  publicacao={this.state.uuidPublicacao} 
                  imagemPublicacaoMural={this.imagemPublicacaoMural}
                  titulo="COMENTÁRIOS"
                >
                </ModalComentarios>) : ""}
              <div className="desktop-content">
                <div className="desktop-content-left">
                  <DinamicasHeaderStyle>
                    <DinamicasHeader
                      dinamica={this.state.mural}
                      theme={this.state.mural.theme}
                      dinamicaNome={i18n.message("dinamica.mural.nome", "Mural Social")}
                      dinamicaAlt={i18n.message("alt.dinamica.mural", "Ícon de balões de conversao estilo gibi.")}
                      dinamicaImg={location && location.state && location.state.icon ? location.state.icon : "/new_novos_habitos.svg"}
                    />
                    {this.state.numeroPublicacao && this.state.numeroPublicacao > 9 ? (
                      <div className="input-wrap ">
                        <div className="field">
                          <div className="control has-icons-left has-icons-right">
                            <input className="input" value={this.state.name} onKeyPress={this.handleKeyPress} onChange={this.alterarNomeBusca} placeholder={i18n.message("dinamica.mural.busca.placeholder", "Busque uma publicação")}/>
                            <span className="icon is-small is-left">
                              <i className="fas fa-search" aria-label={i18n.message("aria.label.icone.busca.publicacao", "Icone de buscar publicação")}></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : ""}
                  </DinamicasHeaderStyle>
                  <div className="botao-publicacao-desktop">
                    <BotaoPrimario
                      className="my-4 "
                      nome={i18n.message("dinamica.mural.publicar", "ESCREVER PUBLICAÇÃO")}
                      funcao={this.abrirModalEscrita}
                      ariaStatusMessage={i18n.message("aria.status.mural.botaoescreverpublicacao", "Botão escrever publicação clicado.")}
                    />
                  </div>
                </div>
                <div className="desktop-content-right">
                  <div  className="publicacoes-content">
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.handleScroll}
                      hasMore={true}
                      loader={<div>
                        <Spinner isVisivel={this.state.spinner} />
                      </div>}
                      useWindow={false}
                    >
                      {this.publicacoesMobileOrDesktop()}
                    </InfiniteScroll>
                  </div>
                  <div className="botao-publicacao-mobile">
                    <BotaoPrimario
                      className="my-4 "
                      nome={i18n.message("dinamica.mural.publicar", "ESCREVER PUBLICAÇÃO")}
                      funcao={this.abrirModalEscrita}
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </MuralStyled>
      )
    }
}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n,
    isDesktopMode: state.isDesktopMode
  }
}

export default connect(mapStateToProps)(Mural)
