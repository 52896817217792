import React, { useState } from "react";
import { ReCaptcha } from "react-recaptcha-v3";
import { useStore } from "react-redux";
import WeexMessage from "../../comps/WeexMessage";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";
import WeexModal from "../../comps/weexModais/WeexModal";

function CustomRegisterCampaignDesktop(props) {
  const i18n = useStore().getState().i18n;

  const [unidadeMae, setUnidadeMae] = useState(null);
  const [subareas, setSubareas] = useState();

  const mudarUnidade = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setUnidadeMae(value);
    setSubareas(
      props.campanha.client.units.filter(
        (unidade) => unidade.unidadeMae === value
      )
    );

    props.onChange(event);
  };

  const renderInputUnidade = () => {
    if (props.campanha.client.units && props.campanha.client.units.length > 0) {
      return (
        <div className="field-cadastro">
          <div className="field">
            <label className="label" htmlFor="selectUnit"><WeexMessage code="registro.campo.area" default="Selecione sua área"/>
              <div className="select weex-select select-margin-top-control">
                <select value={props.request.unit} name="unit" onChange={(e) => mudarUnidade(e)} required id="selectUnit">
                  <option value={null}>
                    {i18n.message("select.unidade", "Selecione uma unidade")}
                  </option>
                  {props.campanha.client.units
                    .filter((unidade) => unidade.unidadeMae === null)
                    .map((opcao) => {
                      return (
                        <option value={opcao.uuid} key={opcao.uuid}>
                          {opcao.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </label>
          </div>
        </div>
      );
    }
    return "";
  };

  const renderInputSubUnidade = () => {
    if (unidadeMae !== null && subareas.length > 0) {
      return (
        <div className="field-cadastro ">
          <div className="field">
            <label className="label" htmlFor="selectSubUnit"><WeexMessage code="registro.campo.subarea" default="Selecione sua subárea"/>
              <div className="select weex-select select-margin-top-control">
                <select id="selectSubUnit" value={props.request.subUnit} name="subUnit" onChange={props.onChange}>
                  <option value={null}>
                    {i18n.message("select.subárea", "Selecione uma subárea")}
                  </option>
                  {subareas.map((opcao) => {
                    return (
                      <option value={opcao.uuid} key={opcao.uuid}>
                        {opcao.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </label>
          </div>
        </div>
      );
    }
    return "";
  };
    
    

  const renderColumns = () => {
    const totalFields = props.fields.length;
    const halfFields = Math.ceil(totalFields / 2);
    const [coluna1Linhas] = totalFields % 2 !== 0 ? [halfFields + 1] : [halfFields];
      
    let coluna1 = [];
    let coluna2 = [];
    let tamanhoColuna1 = 0;
    let tamanhoColuna2 = 0;

    props.fields.forEach((f, index) => {
        
      const fieldWrapper = (
        <div className={`field-cadastro`}>
          {props.renderizaCampo(f, index)}
        </div>
      );

      if (index < coluna1Linhas) {
        coluna1.push(fieldWrapper);
        tamanhoColuna1 += 1;
      } else {
        coluna2.push(fieldWrapper);
        tamanhoColuna2 += 1;
      }
    });

    if (tamanhoColuna1 <= tamanhoColuna2) {
      coluna1.push(renderInputUnidade());
      coluna2.push(renderInputSubUnidade());
    } else {
      coluna2.push(renderInputUnidade());
      coluna1.push(renderInputSubUnidade());
    }

    return (
      <>
        <div
          className={
            tamanhoColuna1 > tamanhoColuna2 &&
            props.campanha.client.units.length > 0
              ? "field-group coluna-margin"
              : "field-group"
          }
        >
          {coluna1}
        </div>
        <div
          className={
            tamanhoColuna1 < tamanhoColuna2 &&
            props.campanha.client.units.length > 0
              ? "field-group coluna-margin"
              : "field-group"
          }
        >
          {coluna2}
        </div>
      </>
    );
  };

  return (
    <form onSubmit={props.registro} className="cadastro-desktop">
      {props.erro != null ? (
        <WeexModal
          fecharModalCallback={props.callbackWarning}
          titulo={props.erro.titulo}
          conteudo={props.erro.mensagem}
        />
      ) : null}
      <div className="inputs_forms">{renderColumns()}</div>

      <div className="field-group-checkbox">
        <label htmlFor="acceptTerms" className="checkbox checkbox-label-style" aria-label={i18n.message("aria.label.campo.registro.legal.termos", " Aceitar termos e condições ")}>
          <input
            onChange={props.onChangeAccept}
            type="checkBox"
            name="aceito"
            id="acceptTerms"
          />
          &nbsp;&nbsp;
          {i18n.message("registro.legal.liaceito", "Li e aceito os")}
          <a
            className="link-sublinhado"
            href={
              props.code === "rededor"
                ? "https://weex.digital/termos-rede-dor/"
                : props.campanha.privacyPolicy
                  ? props.campanha.privacyPolicy
                  : "https://weex.digital/politica-de-privacidade-e-uso/"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.message("registro.legal.termos", " Termos e Condições ")}
          </a>
          {i18n.message("registro.legal.daweex", "da Weex")}
        </label>
      </div>
      <ReCaptcha
        sitekey={props.recaptchaToken}
        action="register"
        verifyCallback={props.verifyCallback}
      />

      <div className="button-cadastro-content">
        <BotaoSecundario
          disabled={props.accept || props.captcha ? false : true}
          nome={
            props.processando
              ? i18n.message("geral.aguarde", "AGUARDE...")
              : i18n.message("registro.criar", "CADASTRAR")
          }
        />
        <button className='button-link espacamento-inicio' type='button' onClick={props.modoLogin}>
          {i18n.message("registro.posseuiCadastro", "Já possuo cadastro")}
        </button>
      </div>
    </form>
  );
}

export default CustomRegisterCampaignDesktop;


