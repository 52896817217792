import React, { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";
import WeexInput from "../../comps/WeexInput";
import WeexModal from "../../comps/weexModais/WeexModal";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import { LoginCampaingStyle } from "./style";

function LoginCampaign(props) {
  const [credenciais, setCredenciais] = useState({
    username: null,
    password: null,
    campaign: props.campanha.code,
  });
  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);
  const [haSenha, setHaSenha] = useState(true);
  const history = useHistory();

  const campanhaService = new CampanhaService();
  const eventoCorrente = campanhaService.campanhaCorrente();

  if (!eventoCorrente || !eventoCorrente.tipoInscricao) {
    campanhaService.clearAll();
    if (!window.location.href.endsWith("select")) {
      window.location = "/select";
    }
  }

  const tipoInscricao = eventoCorrente.tipoInscricao;
  const elegibilidade = eventoCorrente.restrictEligibility;
  const nomeMatricula = eventoCorrente.nomeMatricula;
  const obrigatoriedadeDoTermoCampanha = eventoCorrente.legalTerm;

  const i18n = useStore().getState().i18n;
  const isDesktop = useStore().getState().isDesktopMode;
  const dispatch = useDispatch();

  const possuiCampo = (campo) => {
    let possui = false;
    for (let i = 0; i < eventoCorrente.fields.length; i++) {
      if (eventoCorrente.fields[i].field === campo) {
        possui = true;
      }
    }
    return possui;
  };

  const rotuloDoCampo = (campo) => {
    let rotulo = "";
    for (let i = 0; i < eventoCorrente.fields.length; i++) {
      if (eventoCorrente.fields[i].field === campo) {
        if (eventoCorrente.nomeMatricula) {
          rotulo = eventoCorrente.nomeMatricula;
        } else {
          rotulo = eventoCorrente.fields[i].label;
        }
      }
    }
    return rotulo;
  };

  const submit = (event) => {
    event.preventDefault();
    if (processando) {
      return;
    }
    setProcessando(true);
    const service = new UsuarioService();
    service.auth(credenciais, (erro, sucesso) => {
      if (erro) {
        let mensagemInscricao = "";
        let temSenha = true;
        if (eventoCorrente.tipoInscricao !== "CUSTOMIZADO") {
          mensagemInscricao =
            eventoCorrente.tipoInscricao === "EMAIL" ? "e-mail" : nomeMatricula;
        } else {
          if (possuiCampo("MATRICULA")) {
            mensagemInscricao = rotuloDoCampo("MATRICULA");
          } else if (possuiCampo("EMAIL")) {
            mensagemInscricao = rotuloDoCampo("EMAIL");
          } else {
            mensagemInscricao = "login";
          }

          if (!possuiCampo("SENHA")) {
            temSenha = false;
            setHaSenha(false);
          }
        }
        let mensagemPadrao =
          "Verifique seu " + mensagemInscricao + " e senha e tente novamente.";
        if (eventoCorrente.restrictEligibility || !temSenha) {
          mensagemPadrao =
            "Verifique seu " + mensagemInscricao + " e tente novamente.";
        }

        setErro({
          titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
          mensagem: i18n.message("login.erro.denied.mensagem", mensagemPadrao),
        });
        setProcessando(false);
        return;
      }
      if (sucesso) {
        const temaService = new TemaService();
        temaService.removerTema();
        setProcessando(false);
        if (obrigatoriedadeDoTermoCampanha && !sucesso.legalTerm)  {
          history.push("/accept");
        } else {
          campanhaService.contadorCampanha((erro, sucesso) => {
            if (erro) {
              setErro({
                titulo: i18n.message(
                  "atividades.erro.dias.habilitados",
                  "Erro obter lista de dias habilitados"
                ),
              });
              return;
            }
            if (sucesso) {
              if (sucesso !== null) {
                if (sucesso.length > 0) {
                  history.push("/atividades");
                } else {
                  history.push("/home");
                }
              }
            }
          });
        }
        dispatch({ type: "usuario", payload: true });
        props.callbackLogin(sucesso);
      }
    });
  };

  const onChange = (event) => {
    let cred = credenciais;
    cred[event.target.name] = event.target.value;
    setCredenciais(cred);
  };

  const callbackWarning = () => {
    setErro(null);
  };

  let inputTypeText = null;
  let inputTypePassword = null;
  let inputTypeEmail = null;
  if (isDesktop) {
    inputTypeText = "textIcon";
    inputTypePassword = "passwordIcon";
    inputTypeEmail = "emailIcon";
  } else {
    inputTypeText = "text";
    inputTypePassword = "password";
    inputTypeEmail = "email";
  }

  return (
    <LoginCampaingStyle>
      <div>
        <form style={{ width: "100%" }} onSubmit={submit}>
          <div className="login">
            {tipoInscricao === "EMAIL" ? (
              <WeexInput
                icon="fas fa-envelope"
                type={inputTypeEmail}
                name="username"
                id="username"
                placeholder={i18n.message("registro.campo.email", "E-mail")}
                required={true}
                onChange={onChange}
              />
            ) : tipoInscricao !== "CUSTOMIZADO" ? (
              <WeexInput
                icon="fas fa-user"
                type={inputTypeText}
                name="username"
                id="username"
                placeholder={nomeMatricula}
                required={true}
                onChange={onChange}
              />
            ) : possuiCampo("MATRICULA") ? (
              <WeexInput
                icon="fas fa-user"
                type={inputTypeText}
                name="username"
                id="username"
                placeholder={rotuloDoCampo("MATRICULA")}
                required={true}
                onChange={onChange}
              />
            ) : (
              <WeexInput
                icon="fas fa-envelope"
                type={inputTypeEmail}
                name="username"
                id="username"
                placeholder={rotuloDoCampo("EMAIL")}
                required={true}
                onChange={onChange}
              />
            )}

            {!elegibilidade &&
            (tipoInscricao !== "CUSTOMIZADO" || possuiCampo("SENHA")) ? (
                tipoInscricao !== "CUSTOMIZADO" ? (
                  <WeexInput
                    icon="fas fa-lock"
                    type={inputTypePassword}
                    name="password"
                    id="password"
                    placeholder={i18n.message("registro.campo.senha", "Senha")}
                    required={true}
                    onChange={onChange}
                  />
                ) : (
                  <WeexInput
                    icon="fas fa-lock"
                    type={inputTypePassword}
                    name="password"
                    id="password"
                    placeholder={rotuloDoCampo("SENHA")}
                    required={true}
                    onChange={onChange}
                  />
                )
              ) : null}

            <span style={{ textAlign: "center" }}>
              {erro != null ? (
                <WeexModal
                  fecharModalCallback={callbackWarning}
                  titulo={erro.titulo}
                  conteudo={erro.mensagem}
                />
              ) : null}
              <div className="espacamento-botao-login" style={{marginBottom: '2.5rem'}}>
                <BotaoSecundario
                  disabled={false}
                  nome={
                    processando
                      ? i18n.message("geral.aguarde", "AGUARDE...")
                      : i18n.message("login.entrar", "Entrar")
                  }
                />
              </div>
            </span>
            {(elegibilidade && tipoInscricao !== "ELEGIBILIDADE") ||
            !(tipoInscricao === "ELEGIBILIDADE" && elegibilidade) ||
            (tipoInscricao !== "MATRICULA" && !elegibilidade) ? (
                <div className="login-opcoes">
                  <span
                    style={
                      !haSenha
                        ? { display: "flex", justifyContent: "space-between" }
                        : { display: "flex", justifyContent: "center" }
                    }
                  >
                    {(elegibilidade && tipoInscricao !== "ELEGIBILIDADE") ||
                  !(tipoInscricao === "ELEGIBILIDADE" && elegibilidade) ? (
                        <button
                          className="button-link"
                          type="button"
                          onClick={props.modoRegister}
                        >
                          {i18n.message("registro.inscrevase", "Inscreva-se")}
                        </button>
                      ) : null}
                  </span>
                </div>
              ) : (
                ""
              )}
          </div>
        </form>
      </div>
    </LoginCampaingStyle>
  );
}

export default LoginCampaign;
