import React, { useEffect, useRef, useState } from 'react';
import { useStore } from "react-redux";
import CampanhaService from '../../services/CampanhaService';
import MuralService from '../../services/MuralService';
import UsuarioService from '../../services/UsuarioService';
import VideoIframe from '../VideoIframe/VideoIframe';
import ModalExclusao from '../weexModais/ModalExclusao';
import { ComentariosCurtidasStyle, ConteudoPublicacao, HeaderPublicacao, PublicacaoMuralStyled, QuebraEntreComentarios } from './style';
function PublicacaoMural(props) {
  const i18n = useStore().getState().i18n
  const [curtida, setCurtida] = useState(props.elemento.curtiu);
  const [mostrarModalExclusao, setMostrarModalExclusao] = useState(false);
  const [isGestorOrAdmin, setisGestorOrAdmin] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [numerosCurtidas, setNumerosCurtidas] = useState(props.elemento.qtLikes);
  const [fixado, setFixado] = useState(props.elemento.fix)
  const [mensagemCurtidas, setMensagemCurtidas] = useState("")
  const service = new MuralService();
  const campanhaService = new CampanhaService();
  const usuarioService = new UsuarioService();

  function carregarComentarios() {
    const request = {}
    props.comentarios(props.elemento.uuid, request)
  }

  const ref = useRef()

  function curtir() {
    let like = !curtida;
    if (props.elemento.qtLikes != null) {
      if (like) {
        setNumerosCurtidas(numerosCurtidas + 1);
        props.elemento.qtLikes = props.elemento.qtLikes + 1
      }else{
        setNumerosCurtidas(numerosCurtidas - 1);
        props.elemento.qtLikes = props.elemento.qtLikes - 1
      }
    }
    setCurtida(like);
    props.curtir(like, props.elemento.uuid);
    const mensagemCurtiu = i18n.message("aria.hidden.publicacao.mural.curtido", "curtiu publicação!")
    const mensagemDescurtiu = i18n.message("aria.hidden.publicacao.mural.descurtido", "descurtiu publicação!")
    const mensagem = `${like ? mensagemCurtiu : mensagemDescurtiu}`
    setMensagemCurtidas(mensagem)
  }


  const ativarDropdown = () => {
    setDropdown(true)
  }

  const desativarDropdown = () => {
    setDropdown(false)
  }

  useEffect(() => {
    const isDefinedGestorOrAdmin = () => {
      return usuarioService.isGestor() || usuarioService.isAdmin();
    }
    setisGestorOrAdmin(isDefinedGestorOrAdmin())

    const onBodyClick = event => {

      if (ref.current && ref.current.contains(event.target)) {
        return
      }
      desativarDropdown()
    }

    document.body.addEventListener("click", onBodyClick)

    return () => {
      document.body.removeEventListener("click", onBodyClick)
    }
  }, [campanhaService, usuarioService])

  const atualizarPagina = () => {
    document.location.reload(true);
  }

  const fixarOuDesfixarPublicacao = () => {
        
    service.fixarPublicacao(props.elemento.uuid, (error, sucess) => {
      if (sucess) {
        setFixado(sucess.fix)
      }
      if (error) {
        alert(JSON.stringify(error));
      }
    })   
  }

  const abrirModal = () => {
    setMostrarModalExclusao(true)
  }

  const fecharModal = () => {
    setMostrarModalExclusao(false)
  }

  const deletarComentario = () => {
    service.deleteComentario(props.publicacao, props.elemento.uuid, (error, sucess) => {
      if (sucess) {
        props.deletar(props.elemento.uuid)
      }
      if (error) {
        alert("Você não tem permissão para deletar este comentário!");
      }
    })
  }

  const deletarPublicacao = () => {
    service.deletePublicacao(props.elemento.uuid, (error, sucess) => {
      if (sucess) {
        props.deletar(props.elemento.uuid)
      }
      if (error) {
        alert("Você não tem permissão para deletar esta publicação!");
      }
    })
  }

  const validateUrl = (value) => {
    return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }
    
  let conteudo = props.elemento.conteudo
  conteudo = conteudo.split(" ");
  const conteudoRender = conteudo.map((el) =>{
    if(validateUrl(el)){
      return (<a href={el} target="_blank" rel="noopener noreferrer">{el} </a>)
    }
    return `${el} `;
  })

  return (
    <PublicacaoMuralStyled>
      <div className='publicacao-mural-content'>
        {mostrarModalExclusao && (
          props.isPublicacao
            ? (
              <ModalExclusao
                fecharModalCallback={fecharModal} 
                conteudo={props.msgExcluir} 
                deletar={props.elemento.isPublicacao 
                  ? deletarPublicacao 
                  : deletarComentario} 
                isComentario={false}
              />
            ) : (
              <ModalExclusao
                fecharModalCallback={fecharModal} 
                conteudo={props.msgExcluir} 
                deletar={deletarComentario} 
                isComentario={true}
              />
            )
        )}
        <div>
          <section className="modal-card-body has-text-centered mural_text">
            <HeaderPublicacao>
              <div className="nome-time">
                <div className='titulo-container'>
                  {
                    !isGestorOrAdmin && props.elemento.isPublicacao && fixado ? 
                      <button className="icon button-link" aria-label={props.i18n.message("aria.label.mural.fixar.publicacao", "Fixar publicação")}>
                        <i class="fas fa-thumbtack"></i>
                      </button>
                      :""
                  }
                  {
                    isGestorOrAdmin && props.elemento.isPublicacao ? 
                      <>
                        {
                          fixado ?
                            <>   
                              <button className="icon has-text-success pointer button-link" onClick={fixarOuDesfixarPublicacao}>
                                <i class="fas fa-thumbtack"></i>
                              </button>
                            </>  
                                                
                            :
                            <>
                              <button className="icon has-text-info pointer button-link" onClick={fixarOuDesfixarPublicacao}>
                                <i className="fas fa-thumbtack"></i>
                              </button>
                            </>  
                                                
                        }  
                      </>
                      :""
                  }                                    
                  <h3 className='titulo-container__nome-participante'>
                    {props.elemento.name}
                    {
                      isGestorOrAdmin && props.elemento.isPublicacao ?
                        <>
                          {
                            fixado ?
                              <span>
                                                        - Fixado
                              </span>
                              :
                              <span>
                                                        - Não fixado
                              </span>
                          }
                        </>
                        :""
                    }
                  </h3>
                </div>
                <label>
                  {props.elemento.tempo}
                </label>
              </div>
              {props.elemento.doUsuario || isGestorOrAdmin ? (
                <div className={"dropdown is-rigth " + (dropdown ? "is-active" : "")}>
                  <div className="dropdown-trigger">
                    <button ref={ref} className="icon pointer button-link" onClick={ativarDropdown}>
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                  </div>
                  <div className="dropdown-menu" id={`dropdown-menu-${props.elemento.uuid}`} role="menu">
                    <div className="dropdown-content">
                      <div className="dropdown-item">
                        <button className="button" onClick={abrirModal}>
                          {props.dropdown}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              ) : ""}
            </HeaderPublicacao>
            <ConteudoPublicacao>
              <div className="weex-modal-card-body__texto mural_text">
                <p className="text">
                  {conteudoRender ? conteudoRender :""}                                        
                </p>
              </div>
              {props.elemento.erroImagem ? (
                <div className="weex-modal-card-body__texto imagem-postagem">
                  <p><mark>
                    {props.msgErroImagem}
                  </mark> </p>
                </div>
              ) : ""}
              {props.elemento.processando ? (
                <div className="weex-modal-card-body__texto imagem-postagem margin-imagem-erro" >
                  <p>
                    <mark>
                      {props.msgProcessando}
                    </mark>
                  </p>
                  <button className="icon pointer atualizar-card" onClick={atualizarPagina}>
                    <i class="fas fa-2x fa-redo-alt has-text-grey-light"></i>
                  </button>                           
                </div>
              ) : ""}
              {props.elemento.imagem && props.elemento.isPublicacao ? (
                <div className="weex-modal-card-body__texto imagem-postagem">
                  <div className="modal-card-title wex-modal-card-img">
                    <img
                      src={props.elemento.imagem}
                      alt={props.imagemPublicacaoMural}
                      className="image is-2by1 retirar-padding"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </ConteudoPublicacao>
            <VideoIframe
              urlVideo={props.elemento.urlVideo}
              showControls={true}
              bloquearTelaCheia={false}
              title={i18n.message("aria.label.mural.videoPublicado", "Vídeo publicado")}
            ></VideoIframe>
            {props.elemento.isPublicacao ? (
              <>
                <ComentariosCurtidasStyle>
                  <button className="icon btn-comentario " aria-label={props.ariaLabelButtonComentario} onClick={carregarComentarios}>
                    <i className="fa-2x fas fa-comments has-text-grey-light" ></i>
                    {props.elemento.qtdComentarios ? (
                      <span aria-hidden="true"  className="comentarios values has-background-success has-text-white">{props.elemento.qtdComentarios}</span>
                    ) : ""}
                  </button>
                  <button onClick={curtir} aria-label={props.ariaLabelButtonCurtida} className={"btn-hidden pointer curtida " + (curtida ? 'has-text-danger' : 'has-text-grey-light')}>                    
                    <i className="pointer fas fa-2x fa-heart"></i>
                    {numerosCurtidas ? (
                      <span className="comentarios values has-background-success has-text-white">{numerosCurtidas}</span>
                    ) : ""}                             
                  </button>
                </ComentariosCurtidasStyle>
                <span class="visually-hidden" role="status">{mensagemCurtidas}</span>
              </>
            ) : ("")}
          </section>
        </div>
        {
          props.elemento.isPublicacao ? "" :
            (<QuebraEntreComentarios></QuebraEntreComentarios>)
        }
      </div >
    </PublicacaoMuralStyled>

  )
}
export default PublicacaoMural;
